import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("出库通知单号"),
    dataIndex: "delivery_order_number",
    fixed: "left",
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "delivery_order_number" },
  },
  {
    title: T("往来单位单号"),
    dataIndex: "external_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
    sorter: true,
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("操作人"),
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("单据创建人"),
    dataIndex: "order_creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: T("创建时间"),
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: T("备注"),
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    fixed: "right",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];
